<!--
* @Description: 企业没登录时 修改手机号 手机号不可用
* @Author: sunchunchun
* @CreateDate: 2024/07/17 15:04:14
* @FileName: comEditPhoneNo.vue
!-->
<template>
    <div class="phone-box">
        <div class="cardForm" style="padding: 10px 10px;">
            <div class="title">修改手机号</div>
            <van-form ref="formCode" style="margin-bottom: 90px;">
                <van-field
                    class="phone-input"
                    style="font-size:14px"
                    v-model="form.phone"
                    center
                    label="原手机号码"
                    maxlength="11"
                    type="tel"
                    placeholder="请输入原手机号码"
                />
                <van-field
                    class="phone-input"
                    style="font-size:14px"
                    v-model="form.card"
                    center
                    label="身份证号"
                    maxlength="18"
                    placeholder="请输入身份证号"
                >
                </van-field>
                <van-field
                    class="phone-input"
                    style="font-size:14px"
                    v-model="form.code"
                    center
                    label="统一社会信用代码"
                    placeholder="请输入统一社会信用代码"
                >
                </van-field>
            </van-form>
            <div style="text-align: center;">
                <van-button :loading="loading" class="ant-btn btn-stl-1" style="font-size: 16px;" round type="info"
                    native-type="submit" @click="onNext">
                    下一步
                </van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getUserIdC } from "@/api/user/updataPhone"
import storage from 'store'
export default {
    data() {
        return {
            loading: false,
            form: {
                phone: '',
                card: '',
                code: '',
            },
        }
    },
    created() {

    },
    methods: {
        // 输入信息 对比是否和之前手机号相同
        onNext() {
            if (this.form.phone == '') {
                this.$toast("请输入手机号")
                return false
            }
            if (this.form.card == '') {
                this.$toast("请输入身份证号")
                return false
            }
            if (this.form.code == '') {
                this.$toast("请输入统一社会信用代码")
                return false
            }
            storage.set('phoneNoLogins', 2)
            getUserIdC({
                phone: this.form.phone,
                idcard: this.form.card,
                creditCode: this.form.code,
            }).then(res => {
                if (res.code == 200) {
                    storage.set('phoneNoLoginPhone', this.form.phone)
                    storage.set('phoneNoLoginUserId', res.data)
                    this.$router.push({ path: '/user/updataPhone/child/comPhoneListLogin', query: { phone: this.form.phone, userId: res.data } })
                } else {
                    this.$toast(res.msg)
                }
            })
        },
    }
}
</script>

<style lang='scss' scoped>
.title {
    color: #bea588;
    margin-top: 20%;
    font-size: 60px;
    text-align: center;
    margin-bottom: 100px;
}

.phone-box {
    min-height: 100vh;
    padding: 30px 30px 20px 30px;
}

.ant-btn {
    padding: 0 200px;
}

/deep/ .phone-input {
    background: none;
    border-radius: 10px;
    margin-top: 20px;
}
</style>